import { RouteRecordRaw } from 'vue-router';

const reportRouter: Array<RouteRecordRaw> = [
   {
      path: 'reports',
      name: 'transport-reports',
      children: [
         {
            path: 'overall-detailed-reports',
            name: 'transport-overall-detailed-reports',
            meta: {
               title: 'Sales Transaction',
               permission: 'transport_reports',
            },
            component: () =>
               import('@/views/modules/cargo/views/report/sales/overall-detailed-reports/base.vue'),
         },
         {
            path: 'clients-transaction-report',
            name: 'transport-clients-transaction-report',
            meta: {
               title: 'Client/Products Transaction',
               permission: 'transport_reports',
            },
            component: () =>
               import(
                  '@/views/modules/cargo/views/report/sales/client-transactions-reports/base.vue'
               ),
         },
         {
            path: 'overall-model-report',
            name: 'transport-overall-model-report',
            meta: {
               title: 'Models',
               permission: 'transport_reports',
            },
            component: () =>
               import('@/views/modules/cargo/views/report/sales/overall-model-reports/base.vue'),
         },
         {
            path: 'product-sales-report',
            name: 'transport-product-sales-report',
            meta: {
               title: 'Product Sales',
               permission: 'transport_reports',
            },
            component: () =>
               import('@/views/modules/cargo/views/report/sales/product-sales-report/base.vue'),
         },
         {
            path: 'sales-business-transaction-report',
            name: 'transport-sales-business-transaction-report',
            meta: {
               title: 'Sales Business Transactions',
               permission: 'transport_reports',
            },
            component: () =>
               import(
                  '@/views/modules/cargo/views/report/sales/sales-business-movement-report/base.vue'
               ),
         },
         {
            path: 'client-account-statement-report',
            name: 'transport-client-account-statement-report',
            meta: {
               title: 'Clients Account Statement',
               permission: 'transport_reports',
            },
            component: () =>
               import(
                  '@/views/modules/cargo/views/report/sales/client-account-statement-report/base.vue'
               ),
         },
         {
            path: 'clients-groups-details-transactions-report',
            name: 'transport-clients-groups-details-transactions-report',
            meta: {
               title: 'Clients Groups Details Transactions',
               permission: 'transport_reports',
            },
            component: () =>
               import(
                  '@/views/modules/cargo/views/report/sales/clients-groups-details-transactions-report/base.vue'
               ),
         },
         {
            path: 'salesman-sales-transactions-report',
            name: 'transport-salesman-sales-transactions-report',
            meta: {
               title: 'Sales of representatives',
               permission: 'transport_reports',
            },
            component: () =>
               import(
                  '@/views/modules/cargo/views/report/sales/salesman-sales-transactions-reports/base.vue'
               ),
         },
         {
            path: 'track-invoices-payments',
            name: 'transport-track-invoices-payments',
            meta: {
               title: 'track invoices payments',
               permission: 'transport_reports',
            },
            component: () =>
               import('@/views/modules/cargo/views/report/sales/track-invoices-payments/base.vue'),
         },

         //Purchase
         {
            path: 'purchase-transaction-reports',
            name: 'transport-purchase-transaction-reports',
            meta: {
               title: 'Purchase Transaction',
               permission: 'transport_reports',
            },
            component: () =>
               import(
                  '@/views/modules/cargo/views/report/purchase/purchase-movement-reports/Index.vue'
               ),
         },
         {
            path: 'product-purchase-report',
            name: 'transport-product-purchase-report',
            meta: {
               title: 'Product Purchase',
               permission: 'transport_reports',
            },
            component: () =>
               import(
                  '@/views/modules/cargo/views/report/purchase/product-purchase-report/Index.vue'
               ),
         },
         {
            path: 'purchase-business-transaction-report',
            name: 'transport-purchase-business-transaction-report',
            meta: {
               title: 'Purchases Transaction with tax',
               permission: 'transport_reports',
            },
            component: () =>
               import(
                  '@/views/modules/cargo/views/report/purchase/purchase-business-movement-report/Index.vue'
               ),
         },
         {
            path: 'salesman-purchase-transactions-report',
            name: 'transport-salesman-purchase-transactions-report',
            meta: {
               title: 'Salesman Purchase Transactions',
               permission: 'transport_reports',
            },
            component: () =>
               import(
                  '@/views/modules/cargo/views/report/purchase/salesman-purchase-transactions-report/Index.vue'
               ),
         },
         {
            path: 'vendors-transaction-report',
            name: 'transport-vendors-transaction-report',
            meta: {
               title: 'Vendors Transaction',
               permission: 'transport_reports',
            },
            component: () =>
               import(
                  '@/views/modules/cargo/views/report/purchase/vendors-transaction-report/Index.vue'
               ),
         },
         {
            path: 'vendor-account-statement-report',
            name: 'transport-vendor-account-statement-report',
            meta: {
               title: 'Transporter Account Statement',
               permission: 'transport_reports',
            },
            component: () =>
               import(
                  '@/views/modules/cargo/views/report/purchase/vendor-account-statement-report/Index.vue'
               ),
         },
         {
            path: 'vendors-groups-details-transactions-report',
            name: 'transport-vendors-groups-details-transactions-report',
            meta: {
               title: 'Transporter Groups Total Transactions',
               permission: 'transport_reports',
            },
            component: () =>
               import(
                  '@/views/modules/cargo/views/report/purchase/vendors-groups-details-transactions-report/Index.vue'
               ),
         },
         {
            path: 'generate-transport-transaction-reports',
            name: 'generate-transport-transaction-reports',
            meta: {
               title: 'Transport Transactions',
               permission: 'transport_reports',
            },
            component: () =>
               import(
                  '@/views/modules/cargo/views/report/movement/generate-transport-movement-report/Index.vue'
               ),
         },
         {
            path: 'generate-detailed-transport-transaction-reports',
            name: 'generate-detailed-transport-transaction-reports',
            meta: {
               title: 'Detailed Transport Transactions',
               permission: 'transport_reports',
            },
            component: () =>
               import(
                  '@/views/modules/cargo/views/report/movement/generate-transport-movement-report/detailed.vue'
               ),
         },
         {
            path: 'generate-download-transport-transaction-reports',
            name: 'generate-download-transport-transaction-reports',
            meta: {
               title: 'Downloads transactions',
               permission: 'transport_reports',
            },
            component: () =>
               import(
                  '@/views/modules/cargo/views/report/movement/generate-download-movement-report/Index.vue'
               ),
         },
         {
            path: 'generate-return-transport-transaction-reports',
            name: 'generate-return-transport-transaction-reports',
            meta: {
               title: 'Return Transactions',
               permission: 'transport_reports',
            },
            component: () =>
               import(
                  '@/views/modules/cargo/views/report/movement/generate-return-movement-report/Index.vue'
               ),
         },
         {
            path: 'generate-total-transportation-travel-cost',
            name: 'generate-total-transportation-travel-cost',
            meta: {
               title: 'Transport Transaction Expenses',
               permission: 'transport_reports',
            },
            component: () =>
               import(
                  '@/views/modules/cargo/views/report/movement/transportation-travel-costs-report/Index.vue'
               ),
         },
         {
            path: 'generate-detailed-transportation-travel-cost',
            name: 'generate-detailed-transportation-travel-cost',
            meta: {
               title: 'Detailed Transport Transaction Expenses',
               permission: 'transport_reports',
            },
            component: () =>
               import(
                  '@/views/modules/cargo/views/report/movement/transportation-travel-costs-report/Detailed.vue'
               ),
         },
         {
            path: 'tax-declaration-report',
            name: 'transport-tax-declaration-report',
            meta: {
               title: 'Tax Declaration',
               permission: 'transport_reports',
            },
            component: () =>
               import(
                  '@/views/modules/cargo/views/report/common/tax-declaration-report/Index.vue'
               ),
         },
         {
            path: 'customer-trial-balance-report',
            name: 'transport-customer-trial-balance-report',
            meta: {
               title: 'Customer Trial Balance',
               permission: 'transport_reports',
            },
            component: () =>
               import(
                  '@/views/modules/cargo/views/report/sales/customer-trial-balance-report/base.vue'
               ),
         },
      ],
   },
];

export default reportRouter;
